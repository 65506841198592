import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthUser from "./authUser";
import swal from 'sweetalert';
import { FcAddDatabase } from "react-icons/fc";
import { FcEditImage } from "react-icons/fc";
import { FcDeleteRow } from "react-icons/fc";
import { FcMenu } from "react-icons/fc";
import { FcSearch } from "react-icons/fc";
import { GoKebabHorizontal } from "react-icons/go";
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';

export default function ListContrato() {
  const { http } = AuthUser();
  const [loading, setLoading] = useState(true);
  const [contratoProfile, setContratoProfile] = useState([]);
  const [tablaContrato, setTablaContrato] = useState([]); //Lista los datos de manera estatica
  const [busqueda, setBusqueda] = useState("");

  useEffect(() => {
    fetchContratoProfile();
  }, []);
  const fetchContratoProfile = async () => {
    await http.get('/listContrato').then((res) => {
      console.log(res.data);
      if (res.status === 200) {
        setContratoProfile(res.data.contratos);
        setTablaContrato(res.data.contratos);
      }
      setLoading(false);
    });
  };

  const handleChange = e => {
    setBusqueda(e.target.value);
    filtrar(e.target.value)
  }
  const filtrar = (terminoBusqueda) => {
    var resultadosBusqueda = tablaContrato.filter((elementos) => {
      if (elementos.numContrato.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())) {
        return elementos;

      } else if (elementos.nomEmp.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())) {
        return elementos;

      } else if (elementos.Name.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())) {
        return elementos;
      }
      return false;
    });
    setContratoProfile(resultadosBusqueda);
  }

  const eliminarContrato = (e, id) => {
    e.preventDefault();
    const thisClicked = e.currentTarget;
    thisClicked.innerText = "Eliminando";

    http.delete(`/eliminarContrato/${id}`).then(res => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");

        thisClicked.closest("tr").remove();
      }
      else if (res.data.status === 404) {
        swal("Success", res.data.message, "success");
        thisClicked.innerText = "Eliminar";

      }

    });

  }

  var listaContratos_HTMLTABLE = "";
  if (loading) {
    return <div className="center">
      <button className="btn btn-primary" disabled>
        <span className="spinner-border spinner-border-sm"></span>
        Trayendo Datos..
      </button></div>
  } else {

    listaContratos_HTMLTABLE =
      contratoProfile.map((item) => {
        return (
          <tr key={item.id} align="center">
            <td hidden>{item.idc}</td>
            <td>{item.numContrato}</td>
            <td>{item.nomEmp}</td>
            <td>{item.meses_contrato}</td>
            <td>{item.fecha_inicio}</td>
            <td>{item.fecha_termino}</td>
            <td>{item.plazoSiniestro} Horas</td>
            <td>{item.deducibleAccesorios}%</td>
            <td>{item.deducibleRobo}%</td>
            <td>{item.Reemplazos}%</td>
            <td data-bs-toggle="tooltip" title="% de devolucion anticipada segun contrato">{item.porcentajeDevolucion}%</td>
            <td data-bs-toggle="tooltip" title="% Cobro por admistracion y pago de Autopistas">{item.administracionTag}%</td>
            <td data-bs-toggle="tooltip" title="% Cobro por pago de multas de transito">{item.administracionMulta}%</td>

            <td>{item.Name}</td>
            {/* 
            <td align="center"><Link data-bs-toggle="tooltip" title="Editar" to={`/editContrato/${item.idc}`} ><h3><FcEditImage /></h3></Link></td>
            <td align="center" >
              <Link data-bs-toggle="tooltip" title="Eliminar" to="" onClick={(e) => eliminarContrato(e, item.idc)} ><h3><FcDeleteRow /></h3></Link>
            </td> */}

            <td align="center">
              <Dropdown><Dropdown.Toggle variant="light">
                <FcMenu />
              </Dropdown.Toggle> <Dropdown.Menu>
                  <Dropdown.Item href={`/editContrato/${item.idc}`}><FcEditImage />Editar </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => eliminarContrato(e, item.id)}><FcDeleteRow />Eliminar</Dropdown.Item>
                </Dropdown.Menu></Dropdown>
            </td>

          </tr>
        )
      });
  }

  return (

    <body>

      <nav className="navbar navbar-light" >
        <div className="container-fluid">
          <Link data-bs-toggle="tooltip" title="Crear Nuevo Contrato" className="navbar-brand"
            to="/createContrato"><h3><FcAddDatabase /></h3> </Link>

          <div className="d-flex">
            <input type="text" className="form-control" id="pwd"
             value={busqueda} data-bs-toggle="tooltip" title="Buscar por N° Contrato, Empresa, Ejecutivo"
              placeholder="Buscar por N° Contrato, Empresa, Ejecutivo" onChange={handleChange} />

          </div>
        </div>
      </nav>


      <div className="container mt-12">
        <Table striped bordered hover>
          <thead className='table-dark'>

          <tr align="center">
              {/* https://www.youtube.com/watch?v=HPMSBCfdKKU */}
              <th hidden>N°</th>
              <th>N° Cont</th>
              <th>Empresa</th>
              <th data-bs-toggle="tooltip" title="Meses que durara el contrato">M.Contrato</th>
              <th data-bs-toggle="tooltip" title="Fecha inicio del contrato">Fecha Inicio</th>
              <th data-bs-toggle="tooltip" title="Fecha Termino del contrato">Fecha Termino</th>
              <th data-bs-toggle="tooltip" title="Plazo maximo en horas, que tiene el cliente para hacer ingreso a la pagina web del siniestro">Plazo Siniestro</th>
              <th data-bs-toggle="tooltip" title="Es el pago en uf maximo, que se le cobrara al cliente por gastos reembolsables.
      Siempre y cuando, el siniestro este ingresado dentro del plazo maximo">Ded.Accesorios</th>
              <th data-bs-toggle="tooltip" title="Es el pago en uf maximo, que se le cobrara al cliente por Robo o perdida total de la unidad.
      Siempre y cuando, el siniestro este ingresado dentro del plazo maximo">Ded.Robo</th>
              <th data-bs-toggle="tooltip" title="Corresponde al maximo de reemplazos genericos que se le entregara al cliente,
      en caso de ser requerido.">Reemplazos</th>
              <th data-bs-toggle="tooltip" title="% de devolucion anticipada segun contrato">Dev.Anticipada</th>
              <th data-bs-toggle="tooltip" title="% Cobro por admistracion y pago de Autopistas">Adm.TAG</th>
              <th data-bs-toggle="tooltip" title="% Cobro por admistracion y pago de transito">Adm.Multas</th>

              <th>A Cargo de</th>

              <th> <GoKebabHorizontal /></th>
            </tr>
          </thead>



          <tbody>
            {listaContratos_HTMLTABLE}
          </tbody>
        </Table>
      </div>

    </body>
  );
}