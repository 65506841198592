import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import AuthUser from "./authUser";
import swal from 'sweetalert';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { BsFillArrowLeftSquareFill } from "react-icons/bs";


export default function EditEmpresa(){
  const {http}=AuthUser();
  const navigate=useNavigate();
  const { id } = useParams();
  
  const [trayendo,setTrayendo]=useState([true]);
  const [registerInput, setRegister] = useState({
    id:null,
    nombreEmpresa: '',rut: '',razonSocial:'',ciudads_id: '',direccion: '',creado_por: '',
       error_list: [],
     });
  const [error, setError] = useState([]);

  const [loading,setLoading]=useState(true);
  const [listaCiudades,setListaCiudades]=useState([]);



  useEffect(() => {   
  http.get(`/listaCiudades`).then(res=>{            
    if(res.status===200)
    {
      setListaCiudades(res.data.ciudades)
    }
    setLoading(false);
});
},[]);

   
  useEffect(() => {      
    //Ruta que trae los datos para editar con el id
    http.get(`/editaEmpresa/${id}`).then((res) => {
      if (res.data.status === 200) {
        setRegister(res.data.empresas);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        navigate("/listaEmpresas");
        
      }
      setTrayendo(false);
    }   
    
    );
    
  },[id,navigate]);



  const handleInput = (e) => {
    e.persist();
    setRegister({...registerInput, [e.target.name]: e.target.value });
  }

  const editEmpresa =(e) =>{
    e.preventDefault();

   // const {id} = par();
   const data = {
    nombreEmpresa: registerInput.nombreEmpresa,
    rut: registerInput.rut,
    razonSocial:registerInput.razonSocial,
    ciudads_id: registerInput.ciudads_id,
    direccion: registerInput.direccion,
        //users_id: registerInput.users_id, //id usuario a quien se le asignara la empresa
       // name:registerInput.nombre_emp,// nombre usuario a quien se le asignara la empresa
    creado_por:registerInput.creado_por,  
  };
    //Ruta para cambiar los datos con el id.
    http.put(`/editarEmpresa/${id}`,data).then((res) => {
        console.log(res.data);       
      if(res.data.status===200)
      {
        swal("Success",res.data.message,"success");
      navigate('/listaEmpresas');
       // setError([]);
      }
      else if(res.data.status===422)
      {
        swal("Datos deben estar completos","","error");
        setError(res.data.errors);
      }
      else if(res.data.status===404)
      {
        swal("Error",res.data.message,"error");
        navigate('/listaEmpresas');
      }

    });
  }
  if(trayendo)
  {
    <h4>Trayendo datos para Editar...</h4>
  }
 
if(loading)
{
  <h4>Trayendo ciudad...</h4>
}
  return (

   
    <div>
              
     
      <div className='container py-5'>
            <div className='row justify-content-center'>
                <div className='col-md-6'>
                    <div className='card'>
                    
                        <div className='card-header' >
                          
                            <h4>Edita Empresa</h4>
                            <Link className="nav-link" to="/listaEmpresas">Volver</Link>
                            <div className='card-body'>
                            <form onSubmit={editEmpresa}>

                                    <div className="form-group mb-3">
                                        <label> Nombre</label>
                                        <input type="text" name="nombreEmpresa"  onChange={handleInput}
                                         value={registerInput.nombreEmpresa} className="form-control"/>
                                           <small className="text-danger">{error.nombreEmpresa}</small> 
                                    </div>
                                    <div className="form-group mb-3">
                                        <label> Rut</label>
                                        <input type="text" name="rut"  onChange={handleInput}
                                         value={registerInput.rut} className="form-control"/>
                                          <small className="text-danger">{error.rut}</small>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label> Razon Social</label>
                                        <input type="text" name="razonSocial"  onChange={handleInput}
                                         value={registerInput.razonSocial} className="form-control"/>
                                          <small className="text-danger">{error.razonSocial}</small>
                                    </div>



                                    <div className="form-group mb-3">
                                    <label> Ciudad</label>
                                    <select name="ciudads_id" className="form-control" onChange={handleInput}>
                                    <option value={registerInput.ciudads_id} >{registerInput.ciudads_id}</option>
                                    {listaCiudades.map((items)=>(<option key={items.id} value={items.id}>{items.ciudad}</option>))}
                                    </select>
                                    <small className="text-danger">{error.ciudads_id}</small>                   
                                    </div>

                                    

                                    <div className="form-group mb-3">
                                        <label> Direcciòn</label>
                                        <input type="text" name="direccion"  onChange={handleInput}
                                         value={registerInput.direccion} className="form-control" />
                                            <small className="text-danger">{error.direccion}</small>  
                                    </div>

                                                            
    
                                    <div className="form-group mb-3">
                                        <button type="submit" className="btn btn-primary">Editar</button>
                                    </div>
    
    
    
                                </form>
    
                            </div>
    
                        </div>
    
                    </div>
    
                </div>
    
            </div>
    
        </div> 
    
    
    
    </div>
   
  );
}
