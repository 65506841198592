import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import AuthUser from "./authUser";
import swal from 'sweetalert';
import { FcAddDatabase } from "react-icons/fc";
import { FcEditImage } from "react-icons/fc";
import { FcDeleteRow } from "react-icons/fc";
import { FcSupport } from "react-icons/fc";
import { FcVlc } from "react-icons/fc";
import { FcOk } from "react-icons/fc";
import ListUf from "./listUf";
import { FcMenu } from "react-icons/fc";
import { RiFileExcel2Line } from "react-icons/ri";

import { GoKebabHorizontal } from "react-icons/go";
import Pagination from "../component/Paginacion";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


import Table from 'react-bootstrap/Table';

import Dropdown from 'react-bootstrap/Dropdown';
import ModalExcel from '../component/ModalExcel';


export default function ListFlota() {

  const [smShow, setSmShow] = useState(false);
  
  const handleClose = () => setSmShow(false);

  // const [show, setShow] = useState(false);

  // +++++++++++++++++++++++++++++++++ paginacion +++++++++++++++++++++++
  const [posts, setPosts] = useState([])
  const [loadings, setLoadings] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(10) //7 Per Page
  //+++++++++++++++++++++++++++++++++++++


  const { http } = AuthUser();
  const [loading, setLoading] = useState(true);
  const [flotaProfile, setFlotaProfile] = useState([]);

  const [contarProfile, setcontarProfile] = useState([]);
  const [totalPagarProfile, settotalPagarProfile] = useState([]);

  const [tablaFlota, setTablaFlota] = useState([]); //Lista los datos de manera estatica
  const [busqueda, setBusqueda] = useState("");
  useEffect(() => {
    fetchFlotaProfile();
  }, []);
  const fetchFlotaProfile = async () => {
    await http.get('/listFlota').then((res) => {
      console.log(res.data);
      if (res.status === 200) {
        setFlotaProfile(res.data.flota);
        setcontarProfile(res.data.contar);
        settotalPagarProfile(res.data.sumaTotalPagar);
        setTablaFlota(res.data.flota);
        setLoadings(false);

      }
      setLoading(false);

    });

  };

  const handleChange = e => {
    setBusqueda(e.target.value);
    filtrar(e.target.value)
  }
  const filtrar = (terminoBusqueda) => {
    var resultadosBusqueda = tablaFlota.filter((elementos) => {
      if (elementos.patente.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())) {
        return elementos;

      } else if (elementos.nombreEmpresa.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())) {
        return elementos;

        //     }else if ( elementos.numContrato.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())){
        //       return elementos;                
      }
      return false;

    });
    setFlotaProfile(resultadosBusqueda);

  }

  const eliminarPatente = async(e, id) => {
    e.preventDefault();
    const thisClicked = e.currentTarget;
    thisClicked.innerText = "Patente Eliminada!!";
 
    await http.delete(`/eliminarPatente/${id}`).then(res => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");

       thisClicked.closest("tr").remove();
      }
      else if (res.data.status === 404) {
        swal("Success", res.data.message, "success");
        thisClicked.innerText = "Eliminar";

      }
    });
  }




  if (loadings && flotaProfile.length === 0) {
    return <h2>Loading...</h2>
  }
  //Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = flotaProfile.slice(indexOfFirstPost, indexOfLastPost)
  const howManyPages = Math.ceil(flotaProfile.length / postsPerPage)




  var listaFlotas_HTMLTABLE = "";
  if (loading) {
    return <div className="center">
      <button className="btn btn-primary" disabled>
        <span className="spinner-border spinner-border-sm"></span>
        Trayendo Datos..
      </button></div>

  } else {




    listaFlotas_HTMLTABLE =
      currentPosts.map((item) => {
        return (

          <tr key={item.id} align="center">
            <td hidden>{item.idf}</td>
            <td hidden>{item.contratos_id}</td>
            <td>{item.nombreEmpresa}</td>
            <td data-bs-toggle="tooltip" title="Ciudad Donde Opera">{item.ciudad}</td>
            <td>{item.patente}</td>
            <td hidden>{item.tipo}</td>
            <td hidden>{item.marca}</td>
            <td hidden>{item.modelo}</td>
            <td hidden>{item.año}</td>
            <td hidden>{item.fecha_entrega}</td>
            <td hidden data-bs-toggle="tooltip" title="Meses que dura el contrato" >{item.mesesContrato}</td>
            <td data-bs-toggle="tooltip" title="UF mensual del Arriendo la unidad"> UF {item.uf_mes}</td>

            <td hidden data-bs-toggle="tooltip" title="UF del mes ">$ {item.uf_UltimoDiaMes}</td>
            <td hidden>{item.fecha_facturacion}</td>


            <td hidden>
              <FcVlc />{item.f_ingreso_taller}<br /><FcOk />{item.f_salida_taller}
            </td>

            <td>{item.dias_taller}</td>
            <td>-$ {item.descuento_dias_taller}</td>
            <td hidden>{item.dias_mes}</td>
            <td>{item.total_dias}</td>
            <td >$ {item.total_mes}</td>
            <td>$ {item.total_pagar}</td>
            <td>{item.estado}</td>
            <td hidden data-bs-toggle="tooltip" title={item.observacion}><textarea readOnly>{item.observacion}</textarea></td>

            <td hidden>{item.creado_por}</td>

            <td>

              <Dropdown><Dropdown.Toggle variant="light">
                <FcMenu />
              </Dropdown.Toggle> <Dropdown.Menu>
                  <Dropdown.Item href={`/editFlota/${item.idf}`}><FcEditImage />Editar </Dropdown.Item>
                  {/* <Dropdown.Item onClick={(e) => eliminarPatente(e, item.idf)}><FcDeleteRow />Eliminar</Dropdown.Item> */}
                  <Dropdown.Item href={`/ingresoTaller/${item.idf}`}><FcSupport />Taller </Dropdown.Item>

                  <Dropdown.Item onClick={() => setSmShow(true)} className="me-2" > <FcDeleteRow />Eliminar</Dropdown.Item>
                </Dropdown.Menu></Dropdown>

            </td>

            <Modal size="sm" show={smShow} onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
           Eliminar Patente
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Desea Eliminar la Patente {item.patente}
          
          </Modal.Body>

          <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary"  onClick={(e) => eliminarPatente(e, item.idf)}>
          <FcDeleteRow />Eliminar
          </Button>
        </Modal.Footer>
      </Modal>


          </tr>
        )
      }

      );
  }



  return (
    <body>


    



      <nav className="navbar navbar-light" >


        <div className="container-fluid">
          <Link data-bs-toggle="tooltip" title="Ingresar Nueva Patente" className="navbar-brand"
            to="/createFlota"><h3><FcAddDatabase /></h3> </Link>

          <ListUf />

          <div>
            <Button className="btn btn-secondary btn-sm">Total: {contarProfile} </Button>
          </div>

          <div>
          <ModalExcel /> 
          </div>



          <div>
            <button className="btn btn-secondary btn-sm"> Total Arriendo:$ {totalPagarProfile}</button>
          </div>

          <div className="d-flex">



            <input type="text" className="form-control" id="pwd" value={busqueda}
              placeholder=" N° Contrato / Patente " onChange={handleChange} />

          </div>
        </div>
      </nav>




      <div className="container mt-12">
        <Table striped bordered hover>
          <thead className='table-dark' >


            <tr>
              {/* https://www.youtube.com/watch?v=HPMSBCfdKKU */}
              <th hidden>Id flota</th>
              <th>Empresa</th>
              <th data-bs-toggle="tooltip" title="Ciudad Donde Opera">Ciudad</th>
              <th>Patente</th>
              <th hidden>Tipo</th>
              <th hidden>Marca</th>
              <th hidden>Modelo</th>
              <th hidden>Año</th>
              <th hidden>F.Entrega</th>
              <th hidden data-bs-toggle="tooltip" title="Meses que dura el contrato">M.C</th>
              <th data-bs-toggle="tooltip" title="UF mensual del Arriendo de la unidad">UF CTO</th>
              <th hidden data-bs-toggle="tooltip" title="UF del mes">UF</th>

              <th hidden>Facturacion</th>
              <th hidden>Taller</th>
              <th>Dias Taller</th>
              <th>Desc Taller</th>
              <th hidden>dias_mes</th>
              <th>Dias Op</th>
              <th >total_mes$</th>
              <th>$ A Pagar</th>
              <th>Estado</th>
              <th hidden >Observaciones</th>

              <th hidden >creado por</th>


              <th align="center"> <GoKebabHorizontal /></th>
            </tr>
          </thead>



          <tbody>
            {listaFlotas_HTMLTABLE}

          </tbody>
        </Table>
        <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />

      </div>

    </body>
  );
}