import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthUser from "./authUser";
import swal from 'sweetalert';
import { FcAcceptDatabase } from "react-icons/fc";
import { FcDeleteDatabase } from "react-icons/fc";


export default function CreateContrato(){  

  const navigate=useNavigate();
    const {http}=AuthUser();
    const [registerInput, setRegister] = useState({
      id:'',numContrato:'',meses_contrato:'',fecha_inicio:'',fecha_termino:'',plazoSiniestro:'',
      deducibleAccesorios:'',deducibleRobo:'',Reemplazos:'',porcentajeDevolucion:'',administracionTag:''
      ,administracionMulta:'',empresas_id:'',nombreEmpresa:'',users_id:'',name:'',creado_por:'',error_list: [],});

    const [loading,setLoading]=useState(true); 
    const [listaUsuario,setListaUsuarios]=useState([]);
    const [listaEmpresa,setListaEmpresa]=useState([]);

          useEffect(()=>{  
            http.get(`/listUsers`).then(res=>{            
                if(res.status===200){
                  setListaUsuarios(res.data.users)
                }setLoading(false); }); },[]);   
                
                useEffect(()=>{  
                  http.get(`/listaEmpresas`).then(res=>{            
                      if(res.status===200){
                        setListaEmpresa(res.data.empresas)
                      }setLoading(false); }); },[]); 


    const handleInput = (e) => {
      e.persist();
      setRegister({ ...registerInput, [e.target.name]: e.target.value });
    };

    const registerSubmit = (e) => {
      e.preventDefault();
        const data = {
          numContrato:registerInput.numContrato,
          meses_contrato:registerInput.meses_contrato,
          fecha_inicio:registerInput.fecha_inicio,
          fecha_termino:registerInput.fecha_termino,
          plazoSiniestro:registerInput.plazoSiniestro,
          deducibleAccesorios:registerInput.deducibleAccesorios,
          deducibleRobo:registerInput.deducibleRobo,
          Reemplazos:registerInput.Reemplazos,
          porcentajeDevolucion:registerInput.porcentajeDevolucion,
          administracionTag:registerInput.administracionTag,
          administracionMulta:registerInput.administracionMulta,
          empresas_id:registerInput.empresas_id,
          nombreEmpresa:registerInput.nombreEmpresa,
          users_id:registerInput.users_id,   //users_id: registerInput.users_id, //id usuario a quien se le asignara la empresa
         // name:registerInput.name,
          creado_por:registerInput.creado_por,   
        
      };   
       http.post('/createContrato',data).then((res)=>{
     console.log(res.data);
     if(res.data.status===200)
     {   
       swal("Success",res.data.message,"success");
        navigate('/listContrato')
           }else{
            setRegister({...registerInput,error_list: res.data.validate_errors})
           }
     
    
       });
       if(loading)
       {
        <h4>Trayendo ciudad...</h4>
      }
    }
    return(

      <body>
      <div className="container mt-4">
        <div className="card bg-secondary text-black">
        <form onSubmit={registerSubmit}>
          <div className="card-body">
            {/* <h2>Ingresar Nueva Patente al Contrato</h2> */}
          
            {/* <!-- Multiple inputs 1 --> */}
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-3 bg-ligh">
                  <div className="form-floating mb-3 mt-3">
                   <input type="text" className="form-control" id="numContrato" placeholder="N°Contrato"
            name="numContrato" onChange={handleInput} value={registerInput.numContrato}/> 
         <label className="form-label">N°Contrato:</label>
          <span className="badge bg-danger">{registerInput.error_list.numContrato}</span>   
                  </div>
                </div>

                <div className="col-sm-3 bg-ligh">
                  <div className="form-floating mb-3 mt-3">
                   <input type="number" className="form-control" id="meses_contrato" placeholder="Meses Contrato"
          name="meses_contrato" onChange={handleInput} value={registerInput.meses_contrato}/> 
          <label className="form-label">Meses Contrato</label>
          <span className="badge bg-danger">{registerInput.error_list.meses_contrato}</span>   
                  </div>
                </div>


                <div className="col-sm-3 bg-ligh">
                  <div className="form-floating mb-3 mt-3">
                    <input type="date" className="form-control" id="fecha_inicio" placeholder="Fecha Inicio" 
         name="fecha_inicio" onChange={handleInput} value={registerInput.fecha_inicio}/> 
          <label className="form-label">Fecha Inicio</label>
          <span className="badge bg-danger">{registerInput.error_list.fecha_inicio}</span> 
                  </div>

                </div>
                <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                   <input type="date" className="form-control" id="fecha_termino" placeholder="Fecha Termino" 
         name="fecha_termino" onChange={handleInput} value={registerInput.fecha_termino}/> 
          <label className="form-label">Fecha Termino</label>
          <span className="badge bg-danger">{registerInput.error_list.fecha_termino}</span> 
                    </div>
                </div>
              </div>
            </div>

            {/* <!-- Multiple inputs 2 --> */}
            <div className="container-fluid">
              <div className="row">
              <div className="col-sm-3 bg-ligh">
              <div className="form-floating mb-3 mt-3">
                     <input type="number" className="form-control" id="plazoSiniestro" placeholder="Plazo ingreso Siniestro - Horas"
           name="plazoSiniestro" onChange={handleInput} value={registerInput.plazoSiniestro}  min={12} />
          <label className="form-label">Plazo ingreso Siniestro - Horas</label>
          <span className="badge bg-danger">{registerInput.error_list.plazoSiniestro}</span> 
                  </div>
                  </div>

             
                  <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                   <input type="number" className="form-control" id="deducibleAccesorios" placeholder="UF-Deducible Accesorios"
          name="deducibleAccesorios" onChange={handleInput} value={registerInput.deducibleAccesorios}  min={1} />
          <label className="form-label">UF-Deducible Accesorios</label>
          <span className="badge bg-danger">{registerInput.error_list.deducibleAccesorios}</span> 
                    </div>
                </div>



                <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                    <input type="number" className="form-control" id="deducibleRobo" placeholder="UF-Deducible Robo" 
          name="deducibleRobo" onChange={handleInput} value={registerInput.deducibleRobo} min={1} />
          <label className="form-label">UF-Deducible Robo</label>
          <span className="badge bg-danger">{registerInput.error_list.deducibleRobo}</span> 
                    </div>
                </div>

                <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                    <input type="number" className="form-control" id="Reemplazos" placeholder="% Reemplazos"
          name="Reemplazos" onChange={handleInput} value={registerInput.Reemplazos}  min={1} />
          <label className="form-label">% Reemplazos</label>
          <span className="badge bg-danger">{registerInput.error_list.Reemplazos}</span> 
                    </div>
                </div>
              </div>
            </div>

            {/* <!-- Multiple inputs 3 --> */}
            <div className="container-fluid">
              <div className="row">
              <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                     <input type="number" className="form-control" id="porcentajeDevolucion" placeholder="% Devolucion" 
          name="porcentajeDevolucion" onChange={handleInput} value={registerInput.porcentajeDevolucion}  min={1} />
          <label className="form-label">% Devolucion Anticipada</label>
          <span className="badge bg-danger">{registerInput.error_list.porcentajeDevolucion}</span> 
                    </div>
                </div>

                <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                    <input type="number" className="form-control" id="administracionTag" placeholder="% administracion Tag" 
          name="administracionTag" onChange={handleInput} value={registerInput.administracionTag} />
          <label className="form-label">% administracion Tag</label>
          <span className="badge bg-danger">{registerInput.error_list.administracionTag}</span> 
                    </div>
                </div>

                <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                    <input type="number" className="form-control" id="administracionMulta" placeholder="% administracion Multa" 
          name="administracionMulta" onChange={handleInput} value={registerInput.administracionMulta} />
          <label className="form-label">% administracion Multa</label>
          <span className="badge bg-danger">{registerInput.error_list.administracionMulta}</span> 
                    </div>
                </div>


                <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                    <select className="form-control" id="empresas_id" placeholder="Empresa"
           name="empresas_id" onChange={handleInput}>
            <option defaultValue disable>Elija una Empresa..</option>
            {listaEmpresa.map((items) => (<option key={items.id} value={items.id}>{items.nombreEmpresa}</option>))}
    </select>
        <label className="form-label">Nombre Empresa:</label>
    <span className="badge bg-danger">{registerInput.error_list.empresas_id}</span> 
                    </div>
                </div>


                <div className="col-sm-3 bg-ligh">
                  <div className="form-floating mb-3 mt-3">
                  <select className="form-control" id="users_id" placeholder="Asigne Ejecutivo"
           name="users_id" onChange={handleInput}>
            <option defaultValue disable>Asigne Ejecutivo..</option>
            {listaUsuario.map((items) => (<option key={items.id} value={items.id}>{items.name}</option>))}
    </select>
        <label className="form-label">Ejecutivo Asignado:</label>
    <span className="badge bg-danger">{registerInput.error_list.users_id}</span> 
                  </div>

                </div>
                <div className="col-sm-3 bg-ligh">

                <div className="d-flex">
  <div className="btn-group">
  <button type="button" className="btn"></button>
  <button type="submit" data-bs-toggle="tooltip" title="Ingresar Flota" className="btn btn-outline-secondary"><h1><FcAcceptDatabase/></h1></button>
  <button type="button" className="btn"></button>
  <button type="submit" className="btn btn-outline-secondary">
  <Link data-bs-toggle="tooltip" title="Volver" className="nav-link" to="/listContrato"><h1><FcDeleteDatabase/></h1></Link></button>
  <button type="button" className="btn"></button>
  
  </div>
</div>

                  </div>

                

              </div>
            </div>
            
          </div>
</form>
        </div>
      </div>

    </body>


    )
}