import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import AuthUser from "./authUser";
import swal from 'sweetalert';
import { FcAcceptDatabase } from "react-icons/fc";
import { FcDeleteDatabase } from "react-icons/fc";


export default function ActualizarUf() {

  const navigate = useNavigate();
  const { id } = useParams();
  const { http } = AuthUser();
  const [trayendo, setTrayendo] = useState([true]);
  const [registerInput, setRegister] = useState(
    {
      id: null,
      uf_UltimoDiaMes: '',
      fecha_facturacion: '',
      idc:'',
      numContrato:'',
      contratos_id:'',

      error_list: [],
    });

  const [error, setError] = useState([]);

  const [loading, setLoading] = useState(true);
  const [listaNumeroContrato,setListaNumeroContrato]=useState([]);
  // const [listaCiudades, setListaCiudades] = useState([]);
  const [listaUf, setlistaUf] = useState([]);
  // const [listaMarcas, setListaMarcas] = useState([]);
  // const [listaContrato,setListaContrato]=useState([]);

  // useEffect(() => {
  //   http.get(`/listaCiudades`).then(res => {
  //     if (res.status === 200) {
  //       setListaCiudades(res.data.ciudades)
  //     } setLoading(false);
  //   });
  // }, []);

  useEffect(() => {
    http.get(`/listUf`).then(res => {
      if (res.status === 200) {
        setlistaUf(res.data.uf)
      } setLoading(false);
    });
  }, []);


  useEffect(()=>{  
    http.get(`/uFListaFlotaContrato`).then(res=>{ 
      console.log(res.data);           
        if(res.status===200){
          setListaNumeroContrato(res.data.NumeroContrato)
        }setLoading(false); }); },[]);



  useEffect(() => {
    //Ruta que trae los datos para editar con el id
    http.get(`/actualizarUf/${id}`).then((res) => {
      console.log(res.data);
      if (res.data.status === 200) {
        setRegister(res.data.uf);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        navigate("/listUf");
      }
      setTrayendo(false);
    }

    );
  }, [id, navigate]);

  const handleInput = (e) => {
    e.persist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const registerSubmit = (e) => {
    e.preventDefault();
    const data = {
      uf_UltimoDiaMes: registerInput.uf_UltimoDiaMes,
      fecha_facturacion: registerInput.fecha_facturacion,
      idc:registerInput.idc,
      numContrato:registerInput.numContrato,
      contratos_id:registerInput.contratos_id,

     // creado_por:registerInput.creado_por,

    };

    http.put(`/actualizaUf/${id}`, data).then((res) => {
      console.log(res.data);
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        navigate('/listFlota');
        // setError([]);
      }
      else if (res.data.status === 422) {
        swal("Datos deben estar completos", "", "error");
        setError(res.data.errors);
      }
      else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        navigate('/listFlota');
      }

    });
  }
  if (trayendo) {
    <h4>Trayendo datos para Editar...</h4>
  }


  return (



    <body>

      <div className="container mt-4">
        <div className="card bg-secondary text-black">
          <form onSubmit={registerSubmit}>
            <div className="card-body">
              {/* <h2>Ingresar Nueva Patente al Contrato</h2> */}

              {/* <!-- Multiple inputs 1 --> */}
              <div className="container-fluid">
                <div className="row">


                  <div className="col-sm-3 bg-ligh">
                    <div className="form-floating mb-3 mt-3">
                      <input type="text" className="form-control" placeholder="Uf ultimo dia del mes"
                        name="uf_UltimoDiaMes" id="uf_UltimoDiaMes"
                        onChange={handleInput} value={registerInput.uf_UltimoDiaMes} />
                      <label className="form-label">Uf ultimo dia del mes:</label>
                      <small className="badge bg-danger">{error.uf_UltimoDiaMes}</small>
                    </div>
                  </div>

                  <div className="col-sm-3 bg-ligh">
                    <div className="form-floating mb-3 mt-3">
                      <input type="date" className="form-control" placeholder="Uf ultimo dia del mes"
                        name="fecha_facturacion" id="fecha_facturacion"
                        onChange={handleInput} value={registerInput.fecha_facturacion} />
                      <label className="form-label">Fecha Uf ultimo dia del mes:</label>
                      <small className="badge bg-danger">{error.fecha_facturacion}</small>
                    </div>
                  </div>


                  <div className="col-sm-3 bg-ligh">
              <div className="form-floating mb-3 mt-3">
                    <select className="form-control" name="contratos_id" id="contratos_id" placeholder="Elija Contrato" onChange={handleInput}>
                    <option defaultValue disable>Elija Contrato</option>
                      {listaNumeroContrato.map((items) => (<option key={items.idc} value={items.idc}>{items.nomEmp}</option>))}
                    </select>
                    <label className="form-label">Elija Contrato:</label>
                    <small className="badge bg-danger">{error.contratos_id}</small> 
                  </div>
                  </div>

                

                </div>
              </div>




              <div className="d-flex">
                <div className="btn-group">
                  <button type="button" className="btn"></button>
                  <button type="submit" data-bs-toggle="tooltip" title="Editar Flota" className="btn btn-outline-secondary"><h3><FcAcceptDatabase /></h3></button>
                  <button type="button" className="btn"></button>
                  <button type="submit" className="btn btn-outline-secondary">
                    <Link data-bs-toggle="tooltip" title="Volver" className="nav-link" to="/listFlota"><h3><FcDeleteDatabase /></h3></Link></button>
                  <button type="button" className="btn"></button>

                </div>
              </div>


            </div>
          </form>
        </div>
      </div>

    </body>


  )
}



