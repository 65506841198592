import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import AuthUser from "../components/authUser";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { RiFileExcel2Line } from "react-icons/ri";
import { FcDatabase } from "react-icons/fc";


export default function ExportarExcelFlota() {
  const [lgShow, setLgShow] = useState(false);
  const handleClose = () => setLgShow(false);


  const { id } = useParams();
  const navigate = useNavigate();
  const { http } = AuthUser();
  const [flotaProfile, setFlotaExcel] = useState([]);
  const [listaNumeroContrato, setListaNumeroContrato] = useState([]);
  const [loadings, setLoadings] = useState(false);

  const [registerInput, setRegister] = useState(
    {
      id: null,
      contratos_id: '',
      //ciudads_id: '',
      patente: '',
      // tipo: '',
      // marcas_id: '',
      // modelo: '',
      // año: '',
      // fecha_entrega: '',
      // meses_contrato: '',
      // uf_mes: '',
      // uf_UltimoDiaMes: '',
      // fecha_facturacion: '',
      // f_ingreso_taller: '',
      // f_salida_taller: '',
      // dias_taller: '',
      // dias_mes: '',
      // total_dias: '',
      // total_mes: '',
      // estados_id: '',
      // observacion: '',
      error_list: [],
    });


  useEffect(() => {
    http.get(`/uFListaFlotaContrato`).then(res => {
      console.log(res.data);
      if (res.status === 200) {
        setListaNumeroContrato(res.data.NumeroContrato)
      } setLoadings(false);
    });
  }, []);

  



  useEffect(() => {      
    //Ruta que trae los datos para editar con el id
    http.get(`/exportarFlotaExcel/${id}`).then((res) => {
      console.log(res.data.flotaExcel);    
      if (res.data.status === 200) {
        setFlotaExcel(res.data.flotaExcel);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        navigate("/listFlota");
        
      }
      setLoadings(false);
    }   
    
    );
    
  },[id,navigate]);







  var listaFlotas_HTMLTABLE = "";
  if (loadings) {
    return <div className="center">
      <button className="btn btn-primary" disabled>
        <span className="spinner-border spinner-border-sm"></span>
        Trayendo Datos..
      </button></div>
  } else {



    listaFlotas_HTMLTABLE =
      flotaProfile.map((item) => {
        return (



          <tr key={item.id}>
            <td hidden>{item.id}</td>
            <td>{item.numContrato}</td>
            <td>{item.patente}</td>
            <td>{item.marca}</td>
            <td>{item.modelo}</td>


          </tr>

        )
      });
  }

  return (
    <>


      <Button onClick={() => setLgShow(true)} variant="success">  Excel  &nbsp;<RiFileExcel2Line /></Button>

      <Modal size="lg" show={lgShow} onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <FcDatabase />  Exportar Flota

            <Button></Button>

            <Form.Select id="">
              {listaNumeroContrato.map((items) =>
              (<option key={items.id} href={`/exportarFlotaExcel/${items.id}`}><button>  {items.numContrato}</button>
              </option> ))}

            </Form.Select>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <body>



            <div className="container mt-6">


              <table className="blueTable">


                <thead >


                  <tr>

                    <th>N°Contrato</th>
                    <th>Patente</th>
                    <th>Marca</th>
                    <th>Modelo</th>

                  </tr>
                </thead>



                <tbody>
                  {listaFlotas_HTMLTABLE}
                </tbody>
              </table>
            </div>

          </body>

          {/* <Form.Select id="">
            <option>1</option>
            <option>2</option>
          </Form.Select>            */}

          ...

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="success" onClick={handleClose}>
            Excel  &nbsp;<RiFileExcel2Line />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

}

